@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inria+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700&display=swap");

.rectangle-contact {
  width: auto;
  height: auto;
  background-color: rgba(217, 217, 217, 0.2);
  border-radius: 8px;
  padding: 20px;
  margin: 20px auto;
}

.content-contact {
  display: flex;
  justify-content: space-between;
  gap: 62px;
}

.title-cont {
  font-size: 32px;
  font-family: "Inter", sans-serif;
  font-weight: bold;
  margin-bottom: 4px;
}

.sub-cont {
  font-size: 13px;
  font-family: "Inria Sans", sans-serif;
  font-weight: 300;
  margin-bottom: 10px;
}

.text-title {
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-weight: 500;
  width: 190px;
}

.rec-sal {
  width: 190px;
  height: 40px;
  background-color: rgba(217, 217, 217, 0.4);
  border-radius: 10px;
  font-family: "Inria Sans", sans-serif;
  font-size: 12px;
  font-weight: 300;
  border: 0cap;
}

.rec-mess {
  width: 595px;
  height: 120px;
  background-color: rgba(217, 217, 217, 0.4);
  border-radius: 10px;
  font-family: "Inria Sans", sans-serif;
  font-size: 12px;
  font-weight: 300;
  border: 0cap;
}

.title-privacy {
  font-family: "Inria Sans", sans-serif;
  font-weight: 400;
  font-size: 13px;
}

.checkbox {
  width: 18px;
  height: 18px;
}

.my-check {
  gap: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text-privacy {
  font-family: "Inria Sans", sans-serif;
  font-size: 12px;
  font-weight: 300;
  width: 557px;
  text-align: justify;
}

.div-1 {
  display: flex;
  gap: 13px;
  padding-bottom: 14px;
}

.div-2 {
  display: flex;
  gap: 13px;
  padding-bottom: 14px;
}

.div-3 {
  display: flex;
  gap: 13px;
  padding-bottom: 14px;
}

.content-phone {
  display: flex;
  align-items: center;
  @import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
  @import url("https://fonts.googleapis.com/css2?family=Inria+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700&display=swap");

  .rectangle-contact {
    width: auto;
    height: auto;
    background-color: rgba(217, 217, 217, 0.2);
    border-radius: 8px;
    padding: 20px;
    margin: 20px auto;
  }

  .content-contact {
    display: flex;
    justify-content: space-between;
    gap: 62px;
  }

  .title-cont {
    font-size: 32px;
    font-family: "Inter", sans-serif;
    font-weight: bold;
    margin-bottom: 4px;
  }

  .sub-cont {
    font-size: 13px;
    font-family: "Inria Sans", sans-serif;
    font-weight: 300;
    margin-bottom: 10px;
  }

  .text-title {
    font-family: "Roboto", sans-serif;
    font-size: 13px;
    font-weight: 500;
    width: 190px;
  }

  .rec-sal {
    width: 190px;
    height: 40px;
    background-color: rgba(217, 217, 217, 0.4);
    border-radius: 10px;
    font-family: "Inria Sans", sans-serif;
    font-size: 12px;
    font-weight: 300;
    border: 0cap;
  }

  .rec-mess {
    width: 595px;
    height: 120px;
    background-color: rgba(217, 217, 217, 0.4);
    border-radius: 10px;
    font-family: "Inria Sans", sans-serif;
    font-size: 12px;
    font-weight: 300;
    border: 0cap;
  }

  .title-privacy {
    font-family: "Inria Sans", sans-serif;
    font-weight: 400;
    font-size: 13px;
  }

  .checkbox {
    width: 18px;
    height: 18px;
  }

  .my-check {
    gap: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .text-privacy {
    font-family: "Inria Sans", sans-serif;
    font-size: 12px;
    font-weight: 300;
    width: 557px;
    text-align: justify;
  }

  .div-1 {
    display: flex;
    gap: 13px;
    padding-bottom: 14px;
  }

  .div-2 {
    display: flex;
    gap: 13px;
    padding-bottom: 14px;
  }

  .div-3 {
    display: flex;
    gap: 13px;
    padding-bottom: 14px;
  }

  .content-phone {
    display: flex;
    align-items: center;
    gap: 26px;
  }

  .phone-icon {
    width: 20px;
    height: 19.52px;
  }

  .text-phone {
    font-size: 12px;
    font-family: "Inter", sans-serif;
    font-weight: 300;
  }

  .content-web {
    display: flex;
    align-items: center;
    gap: 26px;
  }

  .content-web a {
    text-decoration: none; /* Menghilangkan garis bawah dari tautan */
  }

  .web-icon {
    width: 20px;
    height: 19.52px;
  }

  .text-web {
    font-size: 12px;
    font-family: "Inter", sans-serif;
    font-weight: 300;
    text-decoration: none;
  }

  .text-web:hover {
    text-decoration: none; /* Pastikan tetap tanpa garis bawah saat di-hover */
  }

  .content-mail {
    display: flex;
    align-items: center;
    gap: 26px;
  }

  .mail-icon {
    width: 20px;
    height: 19.52px;
  }

  .text-mail {
    font-size: 12px;
    font-family: "Inter", sans-serif;
    font-weight: 300;
  }

  .content-map {
    display: flex;
    align-items: center;
    gap: 26px;
  }

  .map-icon {
    width: 20px;
    height: 19.52px;
  }

  .text-map {
    font-size: 12px;
    font-family: "Inter", sans-serif;
    font-weight: 300;
  }

  .submit-btn {
    width: 80px;
    height: 31.25px;
    background-color: #de7902;
    border: 0cap;
    border-radius: 2px;
    font-family: "Inter", sans-serif;
    font-size: 11px;
    font-weight: 500;
  }

  /* Media queries untuk responsivitas */
  @media (max-width: 1024px) {
    .rectangle-contact {
      padding: 15px;
      margin: 15px auto;
    }

    .content-contact {
      flex-direction: column; /* Stack konten secara vertikal */
      gap: 40px;
    }

    .rec-sal,
    .rec-mess {
      width: 100%; /* Buat input field memenuhi lebar layar */
    }

    .div-1,
    .div-2 {
      flex-direction: column; /* Agar form input berjajar ke bawah */
      gap: 10px;
    }

    .submit-btn {
      width: 100%; /* Tombol submit memenuhi lebar layar */
    }

    .content-phone,
    .content-web,
    .content-mail,
    .content-map {
      gap: 20px; /* Sesuaikan jarak antara icon dan teks */
    }
  }

  @media (max-width: 768px) {
    .rectangle-contact {
      padding: 10px;
      margin: 10px auto;
    }

    .title-cont {
      font-size: 24px; /* Kurangi ukuran font untuk mobile */
    }

    .sub-cont,
    .text-title,
    .text-phone,
    .text-web,
    .text-mail,
    .text-map {
      font-size: 11px; /* Ukuran font lebih kecil di mobile */
    }

    .rec-sal,
    .rec-mess {
      font-size: 11px; /* Sesuaikan ukuran font input field */
    }

    .checkbox {
      width: 15px; /* Ukuran checkbox lebih kecil di mobile */
      height: 15px;
    }

    .my-check {
      flex-direction: column; /* Letakkan teks di bawah checkbox */
      align-items: flex-start;
      gap: 5px;
    }

    .submit-btn {
      width: 100%; /* Tombol submit memenuhi lebar penuh */
      height: 40px; /* Sesuaikan tinggi tombol */
    }

    .content-phone,
    .content-web,
    .content-mail,
    .content-map {
      gap: 10px; /* Kurangi jarak antara ikon dan teks */
    }

    .text-privacy {
      width: 100%; /* Sesuaikan lebar teks privacy */
    }
  }

  gap: 26px;
}

.phone-icon {
  width: 20px;
  height: 19.52px;
}

.text-phone {
  font-size: 12px;
  font-family: "Inter", sans-serif;
  font-weight: 300;
}

.content-web {
  display: flex;
  align-items: center;
  gap: 26px;
}

.content-web a {
  text-decoration: none; /* Menghilangkan garis bawah dari tautan */
}

.web-icon {
  width: 20px;
  height: 19.52px;
}

.text-web {
  font-size: 12px;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  text-decoration: none;
  color: black;
}

.text-web:hover {
  text-decoration: none; /* Pastikan tetap tanpa garis bawah saat di-hover */
}

.content-mail {
  display: flex;
  align-items: center;
  gap: 26px;
}

.mail-icon {
  width: 20px;
  height: 19.52px;
}

.text-mail {
  font-size: 12px;
  font-family: "Inter", sans-serif;
  font-weight: 300;
}

.content-map {
  display: flex;
  align-items: center;
  gap: 26px;
}

.content-map a {
  text-decoration: none;
}

.map-icon {
  width: 20px;
  height: 19.52px;
}

.text-map {
  font-size: 12px;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  text-decoration: none;
  color: black;
}

text-map:hover {
  text-decoration: none;
}

.submit-btn {
  width: 80px;
  height: 31.25px;
  background-color: #de7902;
  border: 0cap;
  border-radius: 2px;
  font-family: "Inter", sans-serif;
  font-size: 11px;
  font-weight: 500;
}

/* Media queries untuk responsivitas */
@media (max-width: 1024px) {
  .rectangle-contact {
    padding: 15px;
    margin: 15px auto;
  }

  .content-contact {
    flex-direction: column; /* Stack konten secara vertikal */
    gap: 40px;
  }

  .rec-sal,
  .rec-mess {
    width: 100%; /* Buat input field memenuhi lebar layar */
  }

  .div-1,
  .div-2 {
    flex-direction: column; /* Agar form input berjajar ke bawah */
    gap: 10px;
  }

  .submit-btn {
    width: 100%; /* Tombol submit memenuhi lebar layar */
  }

  .content-phone,
  .content-web,
  .content-mail,
  .content-map {
    gap: 20px; /* Sesuaikan jarak antara icon dan teks */
  }
}

@media (max-width: 768px) {
  .rectangle-contact {
    padding: 10px;
    margin: 10px auto;
  }

  .title-cont {
    font-size: 24px; /* Kurangi ukuran font untuk mobile */
  }

  .sub-cont,
  .text-title,
  .text-phone,
  .text-web,
  .text-mail,
  .text-map {
    font-size: 11px; /* Ukuran font lebih kecil di mobile */
  }

  .rec-sal,
  .rec-mess {
    font-size: 11px; /* Sesuaikan ukuran font input field */
  }

  .checkbox {
    width: 15px; /* Ukuran checkbox lebih kecil di mobile */
    height: 15px;
  }

  .my-check {
    flex-direction: column; /* Letakkan teks di bawah checkbox */
    align-items: flex-start;
    gap: 5px;
  }

  .submit-btn {
    width: 100%; /* Tombol submit memenuhi lebar penuh */
    height: 40px; /* Sesuaikan tinggi tombol */
  }

  .content-phone,
  .content-web,
  .content-mail,
  .content-map {
    gap: 10px; /* Kurangi jarak antara ikon dan teks */
  }

  .text-privacy {
    width: 100%; /* Sesuaikan lebar teks privacy */
  }
}
