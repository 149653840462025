.rectangle-partners {
  width: auto;
  height: auto;
  background-color: rgba(1, 93, 56, 0.09);
  border-radius: 8px;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  margin: 20px auto;
}

.title-partners {
  font-size: 32px;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  margin-bottom: 10px;
}

.content-partners {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.image-partners {
  width: 326px;
  height: 289px;
  object-fit: cover;
  border-radius: 8px;
}

.text-partners {
  width: auto;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 16px;
  font-family: 'Inria Sans', sans-serif;
  font-weight: 300;
  text-align: justify;
  line-height: 1.5;
}

.rectangle-data-center {
  width: auto;
  height: auto;
  background-color: rgba(217, 217, 217, 0.2);
  border-radius: 8px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 50px;
  padding-right: 50px;
  margin: 40px auto;
}

.sub-title {
  font-size: 24px;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-weight: bold;
}

.text-data-center {
  text-align: center;
  font-size: 15px;
  font-family: 'Inria Sans', sans-serif;
  font-weight: 300;
  margin-bottom: 20px;
}

.partners-data-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.gambar-partners {
  width: 100px;
  height: 100px;
}

.gambar-partners {
  display: block;
  transition: transform 0.3s ease; /* Memberikan transisi halus */
}

/* Efek hover: gambar naik 5px ke atas */
.gambar-partners:hover {
  transform: translateY(-7px); /* Menggerakkan gambar ke atas 5px */
}


.rectangle-cabling {
  width: auto;
  height: auto;
  background-color: rgba(1, 93, 56, 0.09);
  border-radius: 8px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 50px;
  padding-right: 50px;
  margin: 40px auto;
}

.sub-title-cabling {
  font-size: 24px;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-weight: bold;
}

.text-cabling {
  text-align: center;
  font-size: 15px;
  font-family: 'Inria Sans', sans-serif;
  font-weight: 300;
  margin-bottom: 20px;
}

.partners-cabling {
  display: flex;
  justify-content: center;
  gap: 50px;
  align-items: center;
}

.gambar-cabling {
  width: 100px;
  height: 100px;
}

.gambar-cabling {
  display: block;
  transition: transform 0.3s ease; /* Memberikan transisi halus */
}

/* Efek hover: gambar naik 5px ke atas */
.gambar-cabling:hover {
  transform: translateY(-7px); /* Menggerakkan gambar ke atas 5px */
}

.rectangle-iot {
  width: auto;
  height: auto;
  background-color: rgba(217, 217, 217, 0.2);
  border-radius: 8px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 50px;
  padding-right: 50px;
  margin: 40px auto;
}

.sub-title-iot {
  font-size: 24px;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-weight: bold;
}

.text-iot {
  text-align: center;
  font-size: 15px;
  font-family: 'Inria Sans', sans-serif;
  font-weight: 300;
  margin-bottom: 20px;
}

.partners-iot {
  display: flex;
  justify-content: center;
  gap: 50px;
  align-items: center;
}

.gambar-iot {
  width: 100px;
  height: 100px;
}

.gambar-iot {
  display: block;
  transition: transform 0.3s ease; /* Memberikan transisi halus */
}

/* Efek hover: gambar naik 5px ke atas */
.gambar-iot:hover {
  transform: translateY(-7px); /* Menggerakkan gambar ke atas 5px */
}

/* Default (Desktop) Styles remain unchanged */

/* Media Queries for Responsive Design */

/* Tablet */
@media (max-width: 1024px) {
  .content-partners {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .text-partners {
    padding-left: 10px;
    padding-right: 10px;
  }

  .image-partners {
    width: 250px;
    height: auto;
  }

  .partners-data-center,
  .partners-cabling,
  .partners-iot {
    flex-wrap: wrap;
    justify-content: center;
  }

  .gambar-partners,
  .gambar-cabling,
  .gambar-iot {
    width: 80px;
    height: 80px;
    margin: 10px;
  }
}

/* Mobile */
@media (max-width: 768px) {
  .title-partners {
    font-size: 28px;
  }

  .sub-title,
  .sub-title-cabling,
  .sub-title-iot {
    font-size: 20px;
  }

  .text-data-center,
  .text-cabling,
  .text-iot {
    font-size: 14px;
  }

  .rectangle-partners,
  .rectangle-data-center,
  .rectangle-cabling,
  .rectangle-iot {
    padding-left: 20px;
    padding-right: 20px;
  }

  .image-partners {
    width: 200px;
    height: auto;
  }

  .gambar-partners,
  .gambar-cabling,
  .gambar-iot {
    width: 60px;
    height: 60px;
  }
}

/* Small Mobile */
@media (max-width: 480px) {
  .title-partners {
    font-size: 24px;
  }

  .sub-title,
  .sub-title-cabling,
  .sub-title-iot {
    font-size: 18px;
  }

  .text-data-center,
  .text-cabling,
  .text-iot {
    font-size: 12px;
  }

  .image-partners {
    width: 150px;
    height: auto;
  }

  .gambar-partners,
  .gambar-cabling,
  .gambar-iot {
    width: 50px;
    height: 50px;
  }
}