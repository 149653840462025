/* Global Styles */
@import url('https://fonts.googleapis.com/css2?family=Inria+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

.recku {
  width: 100%; /* Gambar akan menyesuaikan dengan lebar container */
  max-width: 1100px; /* Membatasi lebar maksimum gambar */
  height: auto; /* Tinggi otomatis mengikuti proporsi gambar */
  border-radius: 8px; /* Jika ingin memberikan border radius */
}

/* Untuk layar 1024px */
@media only screen and (max-width: 1024px) {
  .recku {
    width: 100%; /* Lebar gambar akan 90% dari lebar container */
  }
}

/* Untuk layar 768px */
@media only screen and (max-width: 768px) {
  .recku {
    width: 100%; /* Menyesuaikan gambar lebih kecil */
  }
}

/* Untuk layar 480px */
@media only screen and (max-width: 480px) {
  .recku {
    width: 100%; /* Lebar gambar lebih kecil di layar sempit */
  }
}

/* Untuk layar 360px */
@media only screen and (max-width: 360px) {
  .recku {
    width: 100%; /* Gambar menyesuaikan lagi untuk layar lebih kecil */
  }
}

/* Slider Section */
.slider-container {
  width: 100%;
  height: 440px;
  position: absolute;
  top: 100px;
  left: 0;
}

.slider-image {
  width: 100%;
  height: 440px;
  object-fit: cover;
}

/* Infinite Carousel Section */
.carousel-container {
  width: 100%;
  overflow: hidden;
  position: relative;
  margin-top: 455px;
}

.carousel-track {
  display: flex;
  animation: scroll 20s linear infinite;
}

.carousel-image {
  width: 70px;
  height: 70px;
  margin-right: 80px;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-1560px);
  }
}

/* Rectangle About SDS */
.rectangle-1 {
  width: auto;
  height: auto;
  background-color: rgba(1, 93, 56, 0.09);
  border-radius: 8px;
  padding: 20px;
  margin: 40px auto;
}

.title-1 {
  font-size: 32px;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  margin-bottom: 10px;
}

.subtitle-1 {
  text-align: center;
  font-size: 20px;
  font-family: 'Inria Sans', sans-serif;
  margin-bottom: 20px;
}

.content-1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.image-1 {
  width: 491px;
  height: 300px;
  object-fit: cover;
  border-radius: 8px;
}

.text-1 {
  width: auto;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 16px;
  font-family: 'Inria Sans', sans-serif;
  font-weight: 300;
  text-align: justify;
  line-height: 1.5;
}

.button {
  position: absolute;
  left: 590px;
  top: 1070px;
  width: 103px;
  height: 34px;
  background-color: rgba(217, 217, 217, 0.8);
  color: #000;
  border: none;
  border-radius: 10px;
  font-family: 'Inria Sans', sans-serif;
  font-size: 16px;
  font-weight: 300;
  cursor: pointer;
  margin-top: 20px;
}

/* Rectangle Why Us */
.rectangle-2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: auto;
  height: auto;
  background-color: rgba(217, 217, 217, 0.2);
  border-radius: 8px;
  padding: 20px;
  margin: 40px auto;
}

.content-2 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.judul {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title-2 {
  color: #124d22;
  font-size: 46px;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  margin-bottom: 10px;
  position: relative;
  left: 20px;
}

.sub-content-2 {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.content-2-1,
.content-2-2,
.content-2-3,
.content-2-4 {
  display: flex;
  align-items: center;
  text-align: justify;
}

.image2 {
  width: 83px;
  height: 83px;
}

.text12 {
  width: 495px;
  font-size: 16px;
  font-family: 'Inria Sans', sans-serif;
  font-weight: 300;
  line-height: 1.5;
}

/* Rectangle Awards */
.rectangle-3 {
  width: auto;
  background-color: rgba(1, 93, 56, 0.09);
  border-radius: 8px;
  padding: 20px;
  padding-bottom: 40px;
  margin: 40px auto;
}

.title-3 {
  font-size: 32px;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  margin-bottom: 10px;
}

.card-slider-container {
  width: auto;
  align-items: center;
}

.card {
  width: auto;
  height: 280px;
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
  transition: transform 0.3s ease;
}

.images {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card:hover {
  transform: scale(1.05);
}

.card-image {
  width: 128px;
  height: 162px;
}

.card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}


/* Responsive Styles */
@media (max-width: 1024px) {
  .carousel-container {
    margin-top: 390px;
    /* Sesuaikan margin untuk tablet besar */
  }

  .rectangle-1,
  .rectangle-2,
  .rectangle-3 {
    padding: 15px;
    margin: 20px auto;
  }

  .title-1,
  .title-2,
  .title-3 {
    font-size: 28px;
  }

  .subtitle-1 {
    font-size: 18px;
  }

  .text-1,
  .text12 {
    font-size: 14px;
  }

  .button {
    position: relative;
    /* Ubah menjadi relative untuk mengikuti tata letak */
    left: auto;
    /* Hilangkan left */
    top: auto;
    /* Hilangkan top */
    margin-top: 20px;
    /* Beri jarak antara .text-1 dan .button */
  }

  .image-1 {
    width: 90%;
    height: auto;
  }

  .content-1 {
    flex-direction: column;
    align-items: center;
  }

  .content-2 {
    display: flex;
    flex-direction: column;
    gap: 0;
    padding: auto;
    margin: auto;
  }

  .image2 {
    width: 60px;
    height: 60px;
  }

  .sub-content-2 {
    display: flex;
    flex-direction: column;
    gap: 0;
  }

  .slider-container {
    height: 350px;
    /* Kurangi tingginya untuk layar lebih kecil */
  }

  .slider-image {
    height: 350px;
    object-fit: cover;
  }
}

@media (max-width: 768px) {

  .carousel-container {
    margin-top: 350px;
    /* Sesuaikan margin untuk tablet besar */
  }

  .rectangle-1,
  .rectangle-2,
  .rectangle-3 {
    padding: 10px;
    margin: 15px auto;
  }

  .title-1,
  .title-2,
  .title-3 {
    font-size: 24px;
  }

  .subtitle-1 {
    font-size: 16px;
  }

  .text-1,
  .text12 {
    font-size: 12px;
  }

  .button {
    position: relative;
    /* Ubah menjadi relative untuk mengikuti tata letak */
    left: auto;
    /* Hilangkan left */
    top: auto;
    /* Hilangkan top */
    margin-top: 15px;
    /* Beri jarak antara .text-1 dan .button */
  }

  .image-1 {
    width: 100%;
    height: auto;
  }

  .content-1 {
    flex-direction: column;
    align-items: center;
  }

  .carousel-image {
    width: 50px;
    height: 50px;
    margin-right: 40px;
  }

  .image2 {
    width: 50px;
    height: 50px;
  }

  .sub-content-2 {
    left: 30px;
    /* Atau nilai lain yang Anda anggap sesuai */
  }

  .slider-container {
    height: 300px;
    /* Sesuaikan tinggi untuk tablet */
  }

  .slider-image {
    height: 300px;
    object-fit: cover;
  }
}

@media (max-width: 480px) {

  .carousel-container {
    margin-top: 315px;
    /* Sesuaikan margin untuk ponsel */
  }

  .rectangle-1,
  .rectangle-2,
  .rectangle-3 {
    padding: 8px;
    margin: 10px auto;
  }

  .title-1,
  .title-2,
  .title-3 {
    font-size: 20px;
  }

  .subtitle-1 {
    font-size: 14px;
  }

  .text-1,
  .text12 {
    font-size: 11px;
  }

  .button {
    position: relative;
    /* Ubah menjadi relative untuk mengikuti tata letak */
    left: auto;
    /* Hilangkan left */
    top: auto;
    /* Hilangkan top */
    margin-top: 10px;
    /* Beri jarak antara .text-1 dan .button */
  }

  .image-1 {
    width: 100%;
    height: auto;
  }

  .content-1 {
    flex-direction: column;
    align-items: center;
  }

  .carousel-image {
    width: 40px;
    height: 40px;
    margin-right: 30px;
  }

  .image2 {
    width: 40px;
    height: 40px;
  }

  .sub-content-2 {
    left: 20px;
    /* Atau nilai lain yang Anda anggap sesuai */
  }

  .slider-container {
    height: 250px;
    /* Sesuaikan tinggi untuk layar ponsel */
  }

  .slider-image {
    height: 250px;
    object-fit: cover;
  }
}

@media (max-width: 360px) {
  .carousel-container {
    margin-top: 280px;
    /* Sesuaikan margin untuk ponsel */
  }

  .rectangle-1,
  .rectangle-2,
  .rectangle-3 {
    padding: 6px;
    margin: 5px auto;
  }

  .title-1,
  .title-2,
  .title-3 {
    font-size: 18px;
  }

  .subtitle-1 {
    font-size: 12px;
  }

  .text-1,
  .text12 {
    font-size: 10px;
  }

  .button {
    position: relative;
    /* Ubah menjadi relative untuk mengikuti tata letak */
    left: auto;
    /* Hilangkan left */
    top: auto;
    /* Hilangkan top */
    margin-top: 10px;
    /* Beri jarak antara .text-1 dan .button */
  }

  .image-1 {
    width: 100%;
    height: auto;
  }

  .content-1 {
    flex-direction: column;
    align-items: center;
  }

  .carousel-image {
    width: 30px;
    height: 30px;
    margin-right: 20px;
  }

  .image2 {
    width: 30px;
    height: 30px;
  }

  .sub-content-2 {
    left: 10px;
    /* Atau nilai lain yang Anda anggap sesuai */
  }

  .slider-container {
    height: 200px;
    /* Kurangi lagi untuk layar ponsel kecil */
  }

  .slider-image {
    height: 200px;
    object-fit: cover;
  }
}