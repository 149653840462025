.rectangle-i {
    width: auto;
    height: auto;
    background-color: rgba(217, 217, 217, 0.2);
    border-radius: 8px;
    padding: 20px;
    margin: 20px auto;
}

.content-i {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .content-i-text {
    display: flex;
    flex-direction: column;
  }

  .text-1 {
    width: auto;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 15px;
    font-family: 'Inria Sans', sans-serif;
    font-weight: 300;
    text-align: justify;
    line-height: 1.5;
  }

  .ok {
    width: auto;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 20px;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    text-align: justify;
    line-height: 1.5;
  }

  .part {
    width: auto;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    text-align: justify;
    line-height: 1.5;
  }

  .img-partners {
    display: flex;
    justify-content: center;
    gap: 50px;
    align-items: center;
  }

  .partners-i {
    width: 100px;
    height: 100px;
  }

  /* Media Queries for responsiveness */

/* For screens smaller than 1024px */
@media (max-width: 1024px) {
  .content-i {
      flex-direction: column;
      align-items: flex-start;
  }
  .img-partners {
      flex-direction: column;
      gap: 30px;
  }
}

/* For screens smaller than 768px */
@media (max-width: 768px) {
  .content-i {
      flex-direction: column;
      align-items: flex-start;
  }
  .img-partners {
      flex-direction: column;
      gap: 20px;
  }
}

/* For screens smaller than 480px */
@media (max-width: 480px) {
  .content-i {
      flex-direction: column;
      align-items: flex-start;
  }
  .img-partners {
      flex-direction: column;
      gap: 15px;
  }
}

/* For screens smaller than 340px */
@media (max-width: 340px) {
  .content-i {
      flex-direction: column;
      align-items: flex-start;
  }
  .img-partners {
      flex-direction: column;
      gap: 10px;
  }
}
