@import url('https://fonts.googleapis.com/css2?family=Darker+Grotesque:wght@300..900&display=swap');

.rectangle-service {
    width: auto;
    height: auto;
    background-color: rgba(217, 217, 217, 0.2);
    border-radius: 8px;
    padding-top: 10px;
    padding-bottom: 80px;
    padding-left: 74px;
    padding-right: 74px;
    margin: 20px auto;
}

.title-service {
    font-size: 32px;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    margin-bottom: 10px;
}

.subtitle-service {
    text-align: center;
    font-size: 20px;
    font-family: 'Inria Sans', sans-serif;
    margin-bottom: 20px;
}

.group-service {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.container-service-1 {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 310px;
    height: 240px;
    background-color: rgba(1, 93, 56, 0.06);
    border-radius: 10px;
    box-shadow: 5px 5px 4px 0 rgba(0, 0, 0, 0.25);
}

.text-service {
    font-size: 15px;
    font-family: Darker Grotesque, sans-serif;
    font-weight: 700;
    text-align: center;
}

.list {
    /* list-style-type: square; */
    padding-left: 20px;
    font-size: 15px;
    font-family: Darker Grotesque, sans-serif;
    font-weight: 500;
}

.icons-service {
    width: 60px;
    height: 60px;
}

/* Media Queries for Responsive Design */
@media (max-width: 1024px) {
    .rectangle-service {
        padding-left: 50px;
        padding-right: 50px;
    }

    .container-service-1 {
        width: 80%; /* Mengatur lebar menjadi 80% untuk layar 1024px */
        height: auto; /* Membuat tinggi otomatis */
    }

    .group-service {
        flex-direction: column; /* Mengatur item untuk tampil secara vertikal */
        align-items: center; /* Memusatkan item */
        gap: 20px; /* Menambah jarak antar elemen */
    }

    .title-service {
        font-size: 30px; /* Mengurangi ukuran font untuk judul */
    }

    .subtitle-service {
        font-size: 18px; /* Mengurangi ukuran font untuk subtitle */
    }
}

@media (max-width: 768px) {
    .rectangle-service {
        padding-left: 20px;
        padding-right: 20px;
    }

    .container-service-1 {
        width: 90%; /* Mengatur lebar menjadi 90% untuk layar kecil */
        height: auto; /* Membuat tinggi otomatis */
    }

    .group-service {
        flex-direction: column; /* Mengatur item untuk tampil secara vertikal */
        align-items: center; /* Memusatkan item */
        gap: 20px; /* Menambah jarak antar elemen */
    }

    .title-service {
        font-size: 28px; /* Mengurangi ukuran font untuk judul */
    }

    .subtitle-service {
        font-size: 18px; /* Mengurangi ukuran font untuk subtitle */
    }

    .text-service {
        font-size: 14px; /* Mengurangi ukuran font untuk teks */
    }

    .list {
        font-size: 14px; /* Mengurangi ukuran font untuk list */
    }

    .icons-service {
        width: 50px; /* Mengurangi ukuran ikon */
        height: 50px; /* Mengurangi ukuran ikon */
    }
}

@media (max-width: 480px) {
    .title-service {
        font-size: 24px; /* Ukuran font lebih kecil untuk layar sangat kecil */
    }

    .subtitle-service {
        font-size: 16px; /* Ukuran font lebih kecil untuk subtitle */
    }

    .text-service {
        font-size: 12px; /* Ukuran font lebih kecil untuk teks */
    }

    .list {
        font-size: 12px; /* Ukuran font lebih kecil untuk list */
    }

    .icons-service {
        width: 40px; /* Ukuran ikon lebih kecil untuk layar sangat kecil */
        height: 40px; /* Ukuran ikon lebih kecil untuk layar sangat kecil */
    }
}

@media (max-width: 360px) {
    .rectangle-service {
        padding-left: 10px; /* Mengurangi padding untuk layar 360px */
        padding-right: 10px; /* Mengurangi padding untuk layar 360px */
    }

    .title-service {
        font-size: 20px; /* Ukuran font lebih kecil untuk layar 360px */
    }

    .subtitle-service {
        font-size: 14px; /* Ukuran font lebih kecil untuk subtitle */
    }

    .text-service {
        font-size: 10px; /* Ukuran font lebih kecil untuk teks */
    }

    .list {
        font-size: 10px; /* Ukuran font lebih kecil untuk list */
    }

    .icons-service {
        width: 30px; /* Ukuran ikon lebih kecil untuk layar 360px */
        height: 30px; /* Ukuran ikon lebih kecil untuk layar 360px */
    }
}
