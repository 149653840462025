@import url('https://fonts.googleapis.com/css2?family=Inria+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

.rectangle-about {
    width: auto;
    height: auto;
    background-color: rgba(217, 217, 217, 0.2);
    border-radius: 8px;
    padding: 20px;
    margin: 20px auto;
}

.title {
    font-size: 32px;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    margin-bottom: 10px;
}

.subtitle {
    text-align: center;
    font-size: 20px;
    font-family: 'Inria Sans', sans-serif;
    margin-bottom: 20px;
}

.content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.image {
    width: 491px;
    height: 300px;
    object-fit: cover;
    border-radius: 8px;
}

.text-about {
    width: auto;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 16px;
    font-family: 'Inria Sans', sans-serif;
    font-weight: 300;
    text-align: justify;
    line-height: 1.5;
}

.rectangle-visi-misi {
    width: auto;
    height: auto;
    background-color: rgba(1, 93, 56, 0.09);
    border-radius: 8px;
    padding: 20px;
    margin: 40px auto;
}

.text-visi {
    width: auto;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 16px;
    font-family: 'Inria Sans', sans-serif;
    font-weight: 300;
    text-align: justify;
    line-height: 1.5;
}

.sub-service {
    width: auto;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 20px;
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    text-align: justify;
    line-height: 0;
}

.text-misi {
    width: auto;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 16px;
    font-family: 'Inria Sans', sans-serif;
    font-weight: 300;
    text-align: justify;
    line-height: 1.5;
}

.title-visi-misi {
    font-size: 32px;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    margin-bottom: 10px;
}

.rectangle-proses {
    width: auto;
    height: auto;
    background-color: rgba(217, 217, 217, 0.2);
    border-radius: 8px;
    padding: 20px;
    margin: 40px auto;
}

.image-process {
    width: 746px;
    height: 203px;
    object-fit: cover; /* Ensure aspect ratio */
}

.gambar {
    display: flex;
    justify-content: center;
    align-items: center;
}

.explain {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.judulnya {
    font-size: 12px;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    margin-bottom: 10px;
}

.penjelasannya {
    width: 598px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 12px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    text-align: center;
    line-height: 1.5;
}

/* Media Queries for responsiveness */
@media (max-width: 1024px) {
    .image {
        width: 80%; /* Adjust width */
        height: auto; /* Maintain aspect ratio */
    }
    .image-process {
        width: 90%; /* Adjust width */
        height: auto; /* Maintain aspect ratio */
    }
    .penjelasannya {
      width: auto; /* Keep original width */
    }
}

@media (max-width: 768px) {
    .content {
        flex-direction: column; /* Stack content vertically */
        align-items: center; /* Center align items */
    }
    .image {
        width: 100%; /* Full width */
        height: auto; /* Maintain aspect ratio */
    }
    .image-process {
        width: 100%; /* Full width */
        height: auto; /* Maintain aspect ratio */
    }
    .text-about, .text-visi, .text-misi, .penjelasannya {
        padding: 0 10px; /* Reduce padding */
    }
}

@media (max-width: 480px) {
    .title {
        font-size: 24px; /* Smaller title font size */
    }
    .subtitle {
        font-size: 18px; /* Smaller subtitle font size */
    }
    .text-about, .text-visi, .text-misi, .penjelasannya {
        font-size: 14px; /* Smaller text */
    }
}

@media (max-width: 360px) {
    .title {
        font-size: 20px; /* Even smaller title font size */
    }
    .subtitle {
        font-size: 16px; /* Even smaller subtitle font size */
    }
    .penjelasannya {
      font-size: 12px; /* Smaller font size for explanation */
    }
}
