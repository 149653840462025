@import url("https://fonts.googleapis.com/css2?family=Darker+Grotesque:wght@300..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inria+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@keyframes scaleEffect {
  0% {
    transform: scale(1);
  } /* Ukuran normal */
  50% {
    transform: scale(1.05);
  } /* Membesar sedikit */
  100% {
    transform: scale(1);
  } /* Kembali ke ukuran normal */
}

.FOM {
  display: block;
  transition: transform 0.3s ease;
}

.rectangle-product {
  width: auto;
  height: auto;
  background-color: rgba(217, 217, 217, 0.2);
  border-radius: 8px;
  padding-top: 10px;
  padding-bottom: 80px;
  padding-left: 74px;
  padding-right: 74px;
  margin: 20px auto;
}

.title-product {
  font-size: 32px;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-weight: bold;
  margin-bottom: 10px;
}

.subtitle-product {
  text-align: center;
  font-size: 20px;
  font-family: "Inria Sans", sans-serif;
  margin-bottom: 20px;
}

.group-1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  gap: 30px;
}

.group-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  gap: 30px;
}

.container-1 {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  width: 270px;
  height: 170px;
  background-color: rgba(1, 93, 56, 0.06);
  border-radius: 10px;
  box-shadow: 5px 5px 4px 0 rgba(0, 0, 0, 0.25);
}

.container-1:hover {
  box-shadow: 4px 4px 4px rgba(27, 123, 77, 0.25);
}

.container-1:hover .FOM {
  animation: scaleEffect 0.6s ease-in-out;
  animation-iteration-count: infinite; /* Ulangi terus-menerus */
}

.text1,
.text2,
.text3,
.text4,
.text5,
.text6,
.text7,
.text8 {
  width: 140px;
  height: 40px;
  font-size: 15px;
  font-family: Darker Grotesque, sans-serif;
  font-weight: 600;
  text-align: center;
}

.read {
  font-family: Inria Sans, sans-serif;
  font-size: 10px;
  font-weight: 700;
}

.icons {
  width: 70px;
  height: 70px;
}

.pemanggil {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Media Queries untuk semua layar */
@media (max-width: 1024px) {
  .rectangle-product {
    padding-left: 50px;
    padding-right: 50px;
  }

  .group-1 {
    flex-wrap: wrap;
  }

  .group-2 {
    flex-wrap: wrap;
  }

  .container-1 {
    width: 180px;
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .rectangle-product {
    padding-left: 30px;
    padding-right: 30px;
  }

  .title-product {
    font-size: 28px;
  }

  .subtitle-product {
    font-size: 18px;
  }

  .group-1 {
    flex-direction: column;
    align-items: center;
  }

  .group-2 {
    flex-direction: column;
    align-items: center;
  }

  .container-1 {
    width: 100%;
    max-width: 300px;
  }
}

@media (max-width: 480px) {
  .rectangle-product {
    padding-left: 20px;
    padding-right: 20px;
  }

  .title-product {
    font-size: 24px;
  }

  .subtitle-product {
    font-size: 16px;
  }

  .icons {
    padding: 0;
    width: 40px;
    height: 40px;
  }

  .findmemore {
    font-size: 9px;
  }
}

@media (max-width: 360px) {
  .title-product {
    font-size: 22px;
  }

  .subtitle-product {
    font-size: 14px;
  }

  .icons {
    width: 35px;
    height: 35px;
  }
}
