/* Navbar Base Styles */
nav a {
  text-decoration: none;
}

.navbar {
  width: 100%;
  height: 100px;
  background-color: rgb(245, 245, 245);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: fixed;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  width: 100%;
  height: 81.52px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 0 20px; */
  top: 0;
  left: 0;
}

/* Logo Styles */
.logo {
  height: 81.52px;
}

.logo-image {
  width: 100%;
  max-width: 214px;
  height: auto;
  margin: 7px 0 0 18px;
}

/* Hamburger Menu */
.hamburger {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 22px;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;
}

.hamburger .bar {
  width: 100%;
  height: 3px;
  background-color: black;
  transition: all 0.3s;
}

/* Menu Styles */
.menu {
  display: flex;
  gap: 12px;
}

.menu-item {
  font-family: "Inter", sans-serif;
  font-weight: bold;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
  height: 47px;
  line-height: 47px;
  text-align: center;
  padding: 0 12px;
  transition: color 0.3s;
}

.menu-item:hover {
  color: rgba(0, 0, 0, 1);
}

.solutions {
  position: relative;
  cursor: pointer;
}

.dropdown {
  position: absolute;
  top: 47px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f9f9f9;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  width: 160px;
  height: 100px;
  border-radius: 8px;
  z-index: 999;
}

.dropdown-item {
  display: block;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
  padding: 0px;
  transition: color 0.3s;
}

.dropdown-item:hover {
  color: rgba(0, 0, 0, 1);
}

.page {
  padding: 120px 64px;
  background-color: #f5f5f5;
}

/* Footer Styles */
.footer {
  position: relative;
  left: 0;
  width: 100%;
  background-color: rgba(1, 93, 56, 0.09);
  padding-top: 40px;
  padding-bottom: 5px;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.1);
}

.footer-container {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
}

.footer-section {
  flex: 1;
  margin-right: 20px;
}

.footer-section h4 {
  font-size: 18px;
  color: #000000;
  margin-bottom: 10px;
}

.footer-section p {
  font-size: 14px;
  color: #555555;
  line-height: 1.6;
}

.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section a {
  text-decoration: none;
  font-size: 14px;
  color: #555555;
}

.footer-section ul li {
  display: inline;
  margin-right: 10px;
}

.footer-section ul li a {
  font-size: 14px;
  color: #555555;
  text-decoration: none;
}

.footer-section ul li a:hover {
  color: #000000;
}

.footer-bottom {
  text-align: center;
  padding-top: 5px;
  border-top: 1px solid #e0e0e0;
  margin-top: 20px;
}

.footer-bottom p {
  font-size: 14px;
  color: #888888;
}

/* Media Queries for Responsiveness */

/* 1024px */
@media (max-width: 1024px) {
  .navbar {
    height: 90px;
  }

  .container {
    padding: 0 15px;
  }

  .logo {
    height: 70px;
  }

  .logo-image {
    max-width: 180px;
  }

  .menu-item {
    font-size: 11px;
    padding: 10px 20px;
    text-align: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .page {
    padding: 100px 50px;
  }

  .hamburger {
    display: flex;
  }

  .menu {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100px;
    right: 0;
    background-color: rgb(245, 245, 245);
    width: 100%;
  }

  .menu.menu-open {
    display: flex;
  }

  .dropdown {
    top: 55px;
    width: 150px;
    height: 100px;
    left: 50%;
    transform: translateX(-50%);
  }

  .dropdown-item {
    font-size: 11px;
    padding: 5px;
  }
}

/* 768px */
@media (max-width: 768px) {
  .navbar {
    height: 80px;
  }

  .container {
    flex-direction: column;
    height: auto;
    padding: 10px;
  }

  .logo {
    height: 60px;
  }

  .logo-image {
    max-width: 160px;
    margin: 5px 0 0 10px;
  }

  .menu {
    flex-direction: column;
    gap: 8px;
    display: none;
    position: absolute;
    top: 60px;
    right: 0;
    background-color: rgb(245, 245, 245);
    width: 100%;
  }

  .menu.menu-open {
    display: flex;
  }

  .menu-item {
    font-size: 10px;
    height: auto;
    line-height: normal;
    padding: 10px 20px;
    text-align: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .dropdown {
    top: 30px;
    width: 140px;
    height: 50px;
  }

  .dropdown-item {
    font-size: 10px;
    padding: 5px;
  }

  .page {
    padding: 80px 32px;
  }
}

/* 480px */
@media (max-width: 480px) {
  .navbar {
    height: 60px;
  }

  .container {
    padding: 5px;
  }

  .logo {
    height: 50px;
  }

  .logo-image {
    max-width: 120px;
    margin: 0;
  }

  .menu-item {
    font-size: 8px;
    padding: 6px 10px;
  }

  .dropdown {
    top: 50px;
    width: 130px;
    height: 40px;
  }

  .dropdown-item {
    font-size: 9px;
    padding: 5%;
  }

  .page {
    padding: 60px 20px;
  }
}

/* 360px */
@media (max-width: 360px) {
  .navbar {
    height: 50px;
  }

  .container {
    padding: 0 10px;
  }

  .logo {
    height: 40px;
  }

  .logo-image {
    max-width: 100px;
  }

  .menu-item {
    font-size: 7px;
    padding: 5px 8px;
  }

  .dropdown {
    top: 20px;
    width: 120px;
    height: 40px;
  }

  .dropdown-item {
    font-size: 8px;
    padding: 5px;
  }

  .page {
    padding: 50px 15px;
  }

  .menu {
    flex-direction: column;
    gap: 8px;
    display: none;
    position: absolute;
    top: 50px;
    right: 0;
    background-color: rgb(245, 245, 245);
    width: 100%;
  }

  .hamburger {
    top: 20px;
  }
}

/* Footer Responsive Adjustments */
@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  .footer-section {
    margin-right: 0;
  }

  .footer-section h4 {
    font-size: 16px;
  }

  .footer-section p {
    font-size: 12px;
  }
}

@media (max-width: 360px) {
  .footer-section h4 {
    font-size: 14px;
  }

  .footer-section p {
    font-size: 10px;
  }
}
